import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import LanguageSwitcher from '@/common/language-switcher';

export default function FooterNav() {
  const { t } = useTranslation('common');

  return (
    <div className='flex w-full flex-col justify-between gap-5 xl:flex-row'>
      <div className='flex w-full flex-col gap-5 xl:max-w-[170px]'>
        <Link href='/' className='text-xl font-bold uppercase leading-[100%] text-white transition hover:text-green-300'>
          {t('footer_nav.noping.title')}
        </Link>
        <div className='flex flex-col items-start gap-2.5'>
          <Link href='/servers' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.servers')}
          </Link>
          <Link href='/games' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.games')}
          </Link>
          <Link href='/pricing' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.plans')}
          </Link>
          <Link href='/download' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.download')}
          </Link>
          <Link href='/trial' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.free_trial')}
          </Link>
          <Link href='/work-with-us' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.work_with_us')}
          </Link>
          <Link href='/beta-testing' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.noping.items.beta_testing_program')}
          </Link>
        </div>
      </div>

      <div className='flex w-full flex-col gap-5 xl:max-w-[200px]'>
        <Link href='/technology' className='text-xl font-bold uppercase leading-[100%] text-white transition hover:text-green-300'>
          {t('footer_nav.technology.title')}
        </Link>

        <div className='flex flex-col items-start gap-2.5'>
          <Link href='/features/multi-connection' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.multipath_connection')}
          </Link>
          <Link href='/features/multi-internet' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.multi_internets')}
          </Link>
          <Link href='/boost-fps' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            Boost FPS
          </Link>
          <Link href='/features/ping-reduction' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.ping_reduction')}
          </Link>
          <Link href='/features/packet-loss' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.packet_loss_correction')}
          </Link>
          <Link href='/features/disconnect-correction' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.disconnect_correction')}
          </Link>
          <Link href='/features/ping-spikes-correction' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.ping_spikes_correction')}
          </Link>
          <Link href='/features/keyboard-speed-enhancement' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.keyboard_speed_enhancement')}
          </Link>
          <Link href='/features/comprehensive-statistics' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.comprehensive_statistics')}
          </Link>
          <Link href='/features/connection-monitoring' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.connection_monitoring')}
          </Link>
          <Link href='/features/manual-server' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.manual_server_switch')}
          </Link>
          <Link href='/features/ip-block' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            IP Block
          </Link>
          <Link href='/features/dns-optimization' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.technology.items.dns_optimization')}
          </Link>
          <Link href='/features/audiopad' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            AudioPad
          </Link>
          <Link href='/features/green-aim' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            Green Aim
          </Link>
          <Link href='/pro-settings' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            Pro Settings
          </Link>
        </div>
      </div>

      <div className='flex w-full flex-col gap-5 xl:max-w-[140px]'>
        <Link href='/support' className='text-xl font-bold uppercase leading-[100%] text-white transition hover:text-green-300'>
          {t('footer_nav.help.title')}
        </Link>
        <div className='flex flex-col items-start gap-2.5'>
          <Link href='/tutorial/windows' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.windows_tutorial')}
          </Link>
          <Link href='/tutorial/ios' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.ios_tutorial')}
          </Link>
          <Link href='/tutorial/android' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.android_tutorial')}
          </Link>
          <Link href='/support' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.support_center')}
          </Link>
          {/* <Link href='/b2b-contact' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
						{t('footer_nav.help.items.b2b_contact')}
					</Link> */}
          <Link href='/terms/privacy_policy' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.privacy_politics')}{' '}
          </Link>
          <Link href='/terms/terms_of_use' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.terms_of_use')}
          </Link>
          <Link href='/affiliates' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.partners')}
          </Link>
          <Link href='/support/0' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.help.items.faq')}
          </Link>
        </div>
      </div>

      <div className='flex w-full flex-col gap-5 xl:max-w-[200px]'>
        <Link href='/features/ping-reduction' className='text-xl font-bold uppercase leading-[100%] text-white transition hover:text-green-300'>
          {t('footer_nav.blog.title')}
        </Link>
        <div className='flex flex-col items-start gap-2.5'>
          <Link href='/guia-reduzir-lag' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.blog.items.0')}
          </Link>
          <Link href='/jitter' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.blog.items.1')}
          </Link>
          <Link href='/jogos-competitivos' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.blog.items.2')}
          </Link>
          <Link href='/melhorar-ping' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.blog.items.3')}
          </Link>
          <Link href='/reduzir-packet-loss' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.blog.items.4')}
          </Link>
        </div>
      </div>

      <div className='flex w-full flex-col gap-5 xl:max-w-[210px]'>
        <Link className='text-xl font-bold uppercase leading-[100%] text-white transition hover:text-green-300' href='/evaluate-our-platform'>
          {t('footer_nav.feedbacks.title')}
        </Link>
        <div className='flex flex-col items-start gap-2.5'>
          <Link href='/evaluate-our-platform' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.feedbacks.items.evaluate_our_platform')}
          </Link>
          <Link href='/feature-suggestion' className='text-sm font-bold leading-[normal] text-[#616C7B] transition hover:text-green-300'>
            {t('footer_nav.feedbacks.items.feature_suggestions')}
          </Link>
        </div>
        <div className='hidden xl:flex'>
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  );
}
